import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import { Helmet } from "react-helmet";
import Img from 'gatsby-image';
import * as Markdown from 'react-markdown';
import Layout from "../components/layout";
import moment from 'moment';
import style from "../components/News/News.module.css";

const BlogPostContent = ({data, location, ...props}) => {
    const { slug,
        author,
        source,
        topic,
        metaKeywords,
        publishDate,
        title,
        copy,
        image,
        heroVideo } = data.contentfulBlogPost;
    const content = data.contentfulBlogPost;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const titleBlog = `${title}`;
    const url = `https://www.globalmilk.net/blog/${slug}`
    const description = copy.copy
    const videoId = heroVideo.split('v=')[1]
    const metaImage = heroVideo ? `https://img.youtube.com/vi/${videoId.split('&')[0]}/maxresdefault.jpg` : `https:${image.resize.src}`
    const videoSource = `https://www.youtube.com/embed/${videoId.split('&')[0]}?cc_load_policy=1&color=white&${videoId.split('&')[1]}`

    return (
        <Layout location={location}>
        <Helmet>
            <title>{titleBlog}</title>
            <meta name="image" content={metaImage} />
            <meta name="url" content={url} />
            <meta name="description" content={description} />

            <meta property="og:title" content={titleBlog} />
            <meta property="og:image" content={metaImage} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />

            <meta name="twitter:title" content={titleBlog} />
            <meta name="twitter:image" content={metaImage} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:description" content={description} />
            <link rel="canonical" href={url} />
        </Helmet>

        <section className="uk-section uk-section-small">
            <div className={`uk-container uk-container-small`}>
                <h1 className={`${style.postHeader} uk-margin-remove-adjacent uk-margin-remove-top`}>
                    {title}
                </h1>
                <p className="uk-text-large">{description}</p>
                <small className="uk-text-muted uk-text-meta">Posted on {moment(publishDate).format('MMM D, YYYY')}</small>
            </div>
        </section>

        <section className={`uk-section`}>
            <div className={`uk-container uk-container-large`}>
                <div style={{position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden", zIndex: "5"}} className="uk-box-shadow-large">
                    <iframe
                        style={{position: "absolute", top: "0", left: "0", height: "100%", width: "100%"}}
                        src={videoSource}
                        frameBorder="0"
                        title="YouTube video"
                    />
                </div>
            </div>
        </section>

        <div className={`${style.postContent}`}>
            {richText}
        </div>
        </Layout>
    );
};

export default BlogPostContent;
export const newsPostQuery = graphql`
    query newsPostQuery($slug: String) {
        contentfulBlogPost(slug: {
            eq: $slug
        }) {
            author
            source
            topic
            metaKeywords
            publishDate
            title
            copy {
                copy
            }
            content {
                json
            }
            heroImage {
                resize(width: 1600, quality: 100) {
                    src
                }
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            heroVideo
            slug
        }
    }`
